interface IPersistentStorage {
  set (key: string, value: any): void
  get<T = any> (key: string): null | T
}

class PersistentStorage implements IPersistentStorage {
  get<T = any> (key: string): null | T {
    const value = localStorage.getItem(key)

    if (!value) {
      return null
    }

    try {
      const parsedValue = JSON.parse(value)
      return parsedValue
    } catch (err) {
      console.error(err)
      return null
    }
  }

  set (key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const persistentStorage = new PersistentStorage()
