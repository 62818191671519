import { persistentStorage } from './storage'

enum StorageKeys {
  DefaultEntryIndex = 'default-entry-index'
}

export const entries = [
  '...',
  'Малыш',
  'Яняс',
  'Барышня',
  'Пупыш',
  'Вкусняc',
  'Горошечек',
  'Келебекь',
  'Нясик',
  'Нясик',
  'Лучше-всех',
  'Нясончик',
  'Мопсик',
  'Товарищ',
  'Красоточка',
  'Затащас',
  'Кинолог',
  'Попа',
  'Замечательняс'
]

function storeIndex (index: number) {
  persistentStorage.set(StorageKeys.DefaultEntryIndex, index)
}

export function getDefaultEntryIndex () {
  const defaultEntryIndex = persistentStorage.get(StorageKeys.DefaultEntryIndex)
  return defaultEntryIndex || 0
}

export function getNextEntryIndex (previousIndex: number) {
  let randomIndex = null
  do {
    randomIndex = Math.ceil(Math.random() * (entries.length - 1))
  } while (randomIndex === previousIndex)
  storeIndex(randomIndex)
  return randomIndex
}
