import { useState } from 'react'
import * as styles from './styles'

import {
  entries,
  getNextEntryIndex,
  getDefaultEntryIndex
} from './utils/entries'
import './App.css'

const AppVersionTag = () => {
  return (
    <div style={styles.appVersionTagStyle}>v1.2</div>
  )
}

const Wheel = ({ step }: { step: number }) => {
  return (
    <div style={styles.wheelStyleBuilder(step)}>
      {
        entries.map(
          (text, index) => (
            <div style={styles.stepStyleBuilder(index)} key={index}>
              <span className='variant' style={styles.textWrapperStyle}>{text}</span>
            </div>
          )
        )
      }
    </div>
  )
}

function App () {
  const [step, setStep] = useState(getDefaultEntryIndex())
  return (
    <>
      <div className="App">
        <Wheel step={step} />
        <div style={styles.textBlockStyle}>
          Какая гражданочка Вы сегодня?<br />
          <button
            style={styles.buttonStyle}
            onClick={() => setStep(getNextEntryIndex)}>
            Узнать
          </button>
        </div>
      </div>
      <AppVersionTag />
    </>
  )
}

export default App
