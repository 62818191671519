import { entries } from './utils/entries'
import type { CSSProperties } from 'react'

const WHEEL_RADIUS = 5000

const stepSize = 360 / entries.length

export const wheelStyleBuilder = (index: number = 0): CSSProperties => {
  return {
    height: WHEEL_RADIUS + 'px',
    width: WHEEL_RADIUS + 'px',
    position: 'fixed',
    border: '2px solid #000',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: `translateX(-50%) rotate(${index * stepSize}deg)`,
    transition: 'transform 1.5s'
  }
}

export const stepStyleBuilder = (step: number): CSSProperties => {
  return {
    height: '100%',
    width: '100%',
    position: 'absolute',
    transform: `rotate(${step * stepSize}deg)`
  }
}

export const textWrapperStyle: CSSProperties = {
  display: 'inline-block',
  backgroundColor: '#000',
  color: '#fff',
  padding: '5px',
  transform: 'translateY(-50%)'
}

export const textBlockStyle: CSSProperties = {
  position: 'fixed',
  width: '100%',
  top: '25%',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '20px'
}

export const buttonStyle: CSSProperties = {
  fontSize: '20px',
  marginTop: '5px'
}

export const appVersionTagStyle: CSSProperties = {
  position: 'fixed',
  right: '50px',
  top: '50px',
  background: 'lightgreen',
  padding: '4px 8px',
  borderRadius: '5px',
  fontWeight: '900',
  fontSize: '12px'
}